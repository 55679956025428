import React from "react";

const MyDivider = () => {
  return (
    <img
      src={require("../../assets/images/divider.png").default}
      alt="divider"
      className="mx-auto w-64"
    />
  );
};

export default MyDivider;
