import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Members = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [runners, setRunners] = useState(null);
  const [walkers, setWalkers] = useState(null);
  const [riders, setRiders] = useState(null);
  const [ridersTotal, setRidersTotal] = useState(0);
  const [runnersTotal, setRunnersTotal] = useState(0);
  const [walkersTotal, setWalkersTotal] = useState(0);
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const searchInput = useRef();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getMembers().then((data) => {
        console.log(data.data);
        setRiders(data.data.riders);
        setRunners(data.data.runners);
        setWalkers(data.data.walkers);
        setRidersTotal(data.data.ridersTotal);
        setRunnersTotal(data.data.runnersTotal);
        setWalkersTotal(data.data.walkersTotal);
      });
    }
  }, [initialRender]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      title: "Firstname",
      dataIndex: "firstname",
      ...getColumnSearchProps("firstname"),
      sorter: (a, b) => GlobalVariables.compare(a.firstname, b.firstname),
    },
    {
      title: "Lastname",
      dataIndex: "lastname",
      ...getColumnSearchProps("lastname"),
      sorter: (a, b) => GlobalVariables.compare(a.lastname, b.lastname),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => GlobalVariables.compare(a.email, b.email),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
      sorter: (a, b) => GlobalVariables.compare(a.phone, b.phone),
    },
    {
      title: "Registered Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YY HH:mm"),
    },
    {
      title: "Target Km",
      dataIndex: "targetkm",
      ...getColumnSearchProps("targetkm"),
      sorter: (a, b) => a.target - b.target,
    },
    {
      title: "Goal",
      dataIndex: "goal",
      render: (goal) => <span>${goal}</span>,
      sorter: (a, b) => a.goal - b.goal,
    },
    {
      title: "Donation",
      dataIndex: "donation",
      render: (donation) => <span>${donation}</span>,
      sorter: (a, b) => a.donation - b.donation,
    },
    {
      title: "City",
      dataIndex: "state",
      ...getColumnSearchProps("state"),
      sorter: (a, b) => GlobalVariables.compare(a.state, b.state),
    },
    {
      title: "Status",
      render: (item) => (
        <Tag color={item.goal <= item.donation ? "green" : "red"}>
          {item.goal <= item.donation ? "Positive" : "Negative"}
        </Tag>
      ),
    },
  ];
  const exportExcel = () => {
    AdminService.getExcel().then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "members.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div className="max-w-7xl">
      <Button
        className="m-2"
        style={{ float: "left" }}
        type="primary"
        icon={<FileExcelOutlined />}
        onClick={exportExcel}
      >
        Export Excel
      </Button>
      <div className="bg-sari text-blue-900 text-center p-4 uppercase text-lg lg:text-2xl font-bold ">
        {walkersTotal && (
          <div className="flex justify-between mx-4 ">
            <span className="text-lg   font-semibold">
              Total Participants :{" "}
              {riders.length + runners.length + walkers.length}
            </span>
            <span className="text-lg  font-semibold">
              Total Donation from Participants : $
              {ridersTotal + runnersTotal + walkersTotal}
            </span>
          </div>
        )}
      </div>
      <div className="text-sari bg-blue-900 text-center p-4 uppercase text-2xl lg:text-4xl font-bold ">
        Riders
      </div>
      <Table
        loading={riders === null}
        columns={columns}
        bordered
        dataSource={riders}
        rowKey="id"
        size="small"
        className="border border-gray-300 shadow-xl"
        pagination={{ pageSize: 20 }}
        title={() =>
          riders && (
            <div className="flex justify-between mx-4 ">
              <span className="text-lg text-sky-500 font-semibold">
                Total Riders : {riders.length}
              </span>
              <span className="text-lg text-sky-900 font-semibold">
                Total Donation from Riders : ${ridersTotal}
              </span>
            </div>
          )
        }
      />
      <br />
      <br />
      <div className="text-sari bg-blue-900 text-center p-4 uppercase text-2xl lg:text-4xl font-bold ">
        Runners
      </div>
      <Table
        loading={runners === null}
        columns={columns}
        bordered
        dataSource={runners}
        rowKey="id"
        size="small"
        className="border border-gray-300 shadow-xl"
        pagination={{ pageSize: 20 }}
        title={() =>
          runners && (
            <div className="flex justify-between mx-4 ">
              <span className="text-lg text-sky-500 font-semibold">
                Total Runners : {runners.length}
              </span>
              <span className="text-lg text-sky-900 font-semibold">
                Total Donation from Runners : ${runnersTotal}
              </span>
            </div>
          )
        }
      />
      <br />
      <br />
      <div className="text-sari bg-blue-900 text-center p-4 uppercase text-2xl lg:text-4xl font-bold ">
        Walkers
      </div>
      <Table
        loading={walkers === null}
        columns={columns}
        bordered
        dataSource={walkers}
        rowKey="id"
        size="small"
        className="border border-gray-300 shadow-xl"
        pagination={{ pageSize: 20 }}
        title={() =>
          walkers && (
            <div className="flex justify-between mx-4 ">
              <span className="text-lg text-sky-500 font-semibold">
                Total Walkers : {walkers.length}
              </span>
              <span className="text-lg text-sky-900 font-semibold">
                Total Donation from Walkers : ${walkersTotal}
              </span>
            </div>
          )
        }
      />
    </div>
  );
};

export default Members;
