import { Button, Col, Image, Row } from "antd";
import React from "react";
import { useHistory } from "react-router";
import MyDivider from "./MyDivider";

const BannerGreece = () => {
  const router = useHistory();
  return (
    <div
      style={{
        backgroundColor: "#F29F05",
        zIndex: 9999999,
      }}
      className="-mt-40  lg:mt-0 pb-4"
    >
      <div className=" mx-auto lg:ml-2">
        <Row gutter={[16, 16]} align="middle">
          <Col
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="bg-sari w-full"
          >
            {/* <h1 className="text-blue-900 font-extrabold italic text-4xl pt-8 pb-2 text-center">
              ORGANISED BY
            </h1> 
            <MyDivider />*/}
            <div className="p-4 text-center border-2 shadow-lg border-white m-2 lg:m-4 bg-white bg-opacity-50 ">
              <img
                src={require("../../assets/images/afd.png").default}
                alt="afd"
                className="mx-auto h-20 mb-4 mt-2"
              />
              <h1 className="text-blue-900 font-bold  text-3xl uppercase mb-1">
                Thanks everyone
              </h1>
              <MyDivider />
              <div className="!text-base text-left">
                <br />
                <h1 className="font-bold text-xl">
                  {" "}
                  Australian volunteers run-walk-ride for refugees in Greece
                </h1>{" "}
                <br />
                <p>
                  On the occasion of the United Nations World Refugee Week, The
                  <b> Advocates for Dignity (AFD) </b> organised a run, walk and
                  ride activity to raise funds for refugees in dire need in
                  Greece.{" "}
                </p>
                <p>
                  <b> On Sunday, 26th of June</b>, AFD and its partners
                  organised a charity Cycle, Run or Walk for refugees in Greece
                  in 3 major Australian capital cities, Sydney, Melbourne and
                  Brisbane. AFD teamed up with the Australian Relief
                  Organisation (ARO), the Galaxy Foundation, Sirius College,
                  Selimiye Foundation, and Wisdom College to help raise funds
                  for refugees in Greece.{" "}
                </p>
                <p>
                  After the event, Mr. Mehmet Saral, President of AFD, said
                  <b>
                    {" "}
                    they managed to raise just over $15,000, and all the money
                    collected will be sent to Greece via AFD's partnering
                    organisation, ARO.
                  </b>{" "}
                  "AFD will continue these events during Refugee week every year
                  to raise funds and awareness for refugees who need a helping
                  hand to survive in the countries they reside in," Saral added.{" "}
                </p>
                <br />
                <p className="font-bold text-xl">
                  " EU HAS A LOT TO ANSWER FOR REFUGEES IN GREECE"{" "}
                </p>
                <br />
                <p>
                  <b> Sydney Greece Festival President, Ms. Nia Karteris, </b>{" "}
                  also participated in the event. She thanked AFD for the event.
                  She stated, "It's important that we all unite regardless of
                  our background and beliefs. We're all human beings and should
                  fight for the common good.". Karteris criticised the EU for
                  what they have done for refugees in Greece. "I think it's an
                  obligation by the EU to step up and help Greece with the
                  refugees and not let the burden of the Greek government and
                  the Greek people, even though we are hospitable and we try as
                  hard as we can to help as many as we can, it's beyond the
                  human. EU has a lot to answer for this ... In my opinion, they
                  haven't stepped up. They've closed borders. Asylum Seekers
                  can't travel to other parts of Europe, and it's obviously
                  political. It's not in the best interest of EU to have these
                  people come into their country. They prefer to have them in
                  Turkey and use them in the Turkish government." she said.{" "}
                </p>
                <p>
                  Karteris believes more of these initiatives should happen, and
                  she is happy to take it back to her organisation, the Greek
                  community of New South Wales and see what they can do to
                  interact. "Congratulations on today and what you've achieved;
                  I hope it continues, and you'll be part of the Greek festival
                  next year at Darling Harbor," she added.{" "}
                </p>
                <p>
                  More than one hundred people participated in Sunday's event.{" "}
                </p>
                <p>
                  <b> Mr. Gürkan Gürbüz, </b> CEO of ARO, said they would
                  provide food packs for these refugees, who are finding it
                  difficult even to have food to eat daily. "Donations will be
                  delivered to refugees. Campaigns will be continued on various
                  occasions." Gurbuz added.{" "}
                </p>
                <p>
                  <b>Advocates For Dignity (AFD)</b> is an advocacy organisation
                  that seeks the rule of law, democracy and fundamental human
                  rights and liberties with a special focus on Turkey and its
                  citizens abroad. A country with eighty million citizens is
                  experiencing a dramatic decline in its parliamentary democracy
                  under its autocratic leadership.{" "}
                </p>
              </div>
            </div>
          </Col>
          <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="p-2 lg:pr-4 space-y-4 text-center">
              <Image
                src={require("../../assets/images/afd2.jpeg").default}
                alt="banner"
                className="w-full max-w-3xl border-4 border-white mr-4 shadow-xl rounded"
              />
              <Image
                src={require("../../assets/images/greece.jpg").default}
                alt="banner"
                className="w-full max-w-3xl border-4 border-white mr-4 shadow-xl rounded"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BannerGreece;
