import { HeartOutlined } from "@ant-design/icons";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useCallback, useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import MyDivider from "./MyDivider";
import Sponsors from "./Sponsors";

const DonateToEvent = () => {
  const { lg } = useBreakpoint();
  const [value, setValue] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ total: value });
  }, [value]);

  const onFinish = useCallback(
    async function (values) {
      //    event.preventDefault();

      console.log(values);
      setLoading(true);
      if (!stripe || !elements || !values.total) {
        setLoading(false);
        return;
      }

      try {
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          setLoading(false);
          return;
        }

        const obj = {
          ...values,
          total: values.total,
        };
        let response = await AdminService.finalizeDonation({
          ...obj,
          payment: {
            payment_method_id: paymentMethod.id,
          },
        });
        // 3D Secure
        while (response.status === 402) {
          const { paymentIntent, error } = await stripe.handleCardAction(
            response.data.token
          );
          if (error) {
            setLoading(false);
            // unable to pass 3Ds
            return;
          }

          response = await AdminService.finalizeOrder({
            ...obj,
            payment: {
              payment_intent_id: paymentIntent.id,
            },
          });
        }

        const ok = response.status === 200 || response.status === 204;
        if (ok) {
          notification.success({
            message: "Success!",
            description: "Thank you for your donation!",
          });

          form.resetFields();
          console.log("gelen data", response.data);
          setValue(null);
          elements.getElement(CardElement).clear();
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements, form]
  );
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className="min-h-screen  mx-auto">
      <div className="bg-sari relative">
        <img
          src={require("../../assets/images/gift.png").default}
          alt="gift"
          className="max-w-full w-full"
        />
        <div className="lg:text-4xl text-2xl p-2 text-white font-semibold lg:absolute top-24 left-10  uppercase ">
          <p className="text-white">Thank you for your donation</p>
        </div>
      </div>
      <div className="bg-sari p-4 ">
        <div className="mx-auto max-w-4xl">
          <h1 className="text-blue-900 text-center font-extrabold italic text-4xl pt-8 pb-2 ">
            DONATE TO EVENT
          </h1>
          <MyDivider />
          <br />
          <Form
            initialValues={{ isAnonymously: false }}
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            {...GlobalVariables.formLayout}
          >
            <Form.Item name="memberId" className="hidden"></Form.Item>
            <Form.Item
              label="Fullname"
              name="name"
              rules={[{ required: true, message: "Fullname is required!" }]}
            >
              <Input
                placeholder="Please type your fullname"
                size={lg ? "large" : "middle"}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Email is required!" }]}
            >
              <Input
                placeholder="Please type your email"
                size={lg ? "large" : "middle"}
              />
            </Form.Item>
            <Form.Item label="Organisation (opt.)" name="organisation">
              <Input
                placeholder="Please type your organisation name (optional)"
                size={lg ? "large" : "middle"}
              />
            </Form.Item>
            <Form.Item
              label="Personal message"
              name="message"
              rules={[
                {
                  type: "string",
                  max: 25,
                  message: "Over max length (255)",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Type your message (optional)"
                size={lg ? "large" : "middle"}
              />
            </Form.Item>
            <Form.Item
              name="isAnonymously"
              valuePropName="checked"
              wrapperCol={
                lg && {
                  offset: 6,
                  span: 18,
                }
              }
            >
              <Checkbox>I’d prefer to make my donation anonymously</Checkbox>
            </Form.Item>
            <Form.Item
              label={<span>Your Donation</span>}
              name="total"
              className="-mt-4"
              rules={[
                {
                  required: true,
                  message: "Please select your donation value",
                },
              ]}
            >
              <Radio.Group
                value={value}
                buttonStyle="solid"
                style={{ marginBottom: 16 }}
                onChange={(a) => setValue(a.target.value)}
                size={lg ? "large" : "middle"}
              >
                {[20, 50, 100, 150].map((s) => (
                  <Radio.Button key={s} value={s}>
                    ${s}
                  </Radio.Button>
                ))}
              </Radio.Group>
              <InputNumber
                style={{ width: "200px" }}
                min={0}
                max={10000}
                placeholder="Or type your value"
                onChange={(a) => setValue(a)}
                value={value}
                addonBefore="$"
                size={lg ? "large" : "middle"}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg && {
                  offset: 6,
                  span: 18,
                }
              }
            >
              <h4 className="text-base">
                Please Type Your Credit/Debit Card Details
              </h4>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "18px",
                      fontFamily: "monospace",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "red",
                    },
                  },
                }}
                className="st-form"
              />
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                disabled={!stripe || !elements}
                block
                style={{ maxWidth: "500px" }}
                size="large"
                className="text-lg h-14 leading-3"
                icon={<HeartOutlined />}
              >
                Donate Now
              </Button>
            </Form.Item>
          </Form>
          <img
            src={require("../../assets/images/secure-stripe.png").default}
            alt="secure"
            className="w-full max-w-md mx-auto"
          />
        </div>
        <br />
        <br />
        <br />
        <Sponsors />
      </div>
    </div>
  );
};

export default DonateToEvent;
// import React from "react";

// const DonateToEvent = () => {
//   return (
//     <div className="justify-center text-center p-10">Donations Closed!</div>
//   );
// };

// export default DonateToEvent;
