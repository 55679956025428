import React from "react";

import BannerTurkiye from "./BannerTurkiye";
import Fundraisers from "./Fundraisers";
import Sponsors from "./Sponsors";

export default function HomePage() {
  return (
    <div>
      <div>
        <BannerTurkiye />
        <br />
        <div className="text-center justify-center items-center   w-full text-white  text-2xl lg:text-5xl "></div>
        {/* <GreeceBanner />
        <Timer />
        <StepsSave />
        <br />
        <Elocation /> */}
        <br />
        <Fundraisers title="LEADERBOARD" isFull={false} />
        <Sponsors />
      </div>
    </div>
  );
}
