import { LockClosedIcon } from "@heroicons/react/solid";
import { Button, Form, Space } from "antd";
import React, { useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "src/components/context/GlobalContext";
import { AdminService } from "../resources/AdminService";

const LoginLayout = () => {
  const router = useHistory();
  const location = useLocation();
  const { setRole } = useContext(GlobalContext);
  // eslint-disable-next-line no-unused-vars
  const { from } = location.state || { from: { pathname: "/" } };
  const onFinish = (v) => {
    AdminService.login(v).then((data) => {
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("user", JSON.stringify(data.data.user));
      console.log("gelen:", data.data);
      setRole(data.data.user.role);

      //from.pathname === "/" ? router.push("/admin") : router.replace(from);
      router.push("/admin");
    });
  };
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <div className="login-page">
      <div className=" flex items-center justify-center bg-sky-900 bg-opacity-80 rounded-lg py-6 px-4 sm:px-6 lg:px-8 shadow-lg border border-gray-400">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-20 w-auto"
              src={require("../assets/images/rwr.png").default}
              alt="Logo"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-sky-500">
              Sign in to your account
            </h2>
          </div>
          <Form className="mt-8 space-y-6" onFinish={onFinish}>
            <Form.Item name="email">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-sky-500 focus:border-sky-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
            </Form.Item>
            <Form.Item name="password">
              <div className="-mt-10">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-sky-500 focus:border-sky-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </Form.Item>

            {/* <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-sky-600 focus:ring-sky-500 border-gray-300 rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>
            <div className="text-sm">
              <Link
                to="#"
                className="font-medium text-sky-600 hover:text-sky-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div> */}

            <Space className="w-full" direction="vertical">
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-sky-300 group-hover:text-sky-400"
                    aria-hidden="true"
                  />
                </span>
                Sign in
              </button>
              <Button
                onClick={() => router.push("/")}
                block
                style={{ borderRadius: "8px", height: "37px" }}
              >
                Go to homepage
              </Button>
            </Space>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default LoginLayout;
