import { Col, Row, Space } from "antd";
import React from "react";
import MyDivider from "./MyDivider";

const Sponsors = () => {
  return (
    <div>
      <div className="text-sari bg-blue-900 text-center p-4 uppercase text-2xl lg:text-4xl font-extrabold italic">
        event organizer , sponsor & partner
      </div>
      <div className="bg-sari  flex justify-center">
        <div className="max-w-7xl mx-auto   ">
          <Row gutter={[32, 32]} align="middle" className="my-10">
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="w-full text-center">
                <h1 className="text-blue-900 font-extrabold italic text-4xl pt-8 pb-2 ">
                  ORGANISED BY
                </h1>
                <MyDivider />
                <Space size="large">
                  <img
                    src={require("../../assets/images/afd.png").default}
                    alt="afd"
                    className="h-16  mt-4 mx-auto"
                  />
                  <img
                    src={require("../../assets/images/aro.png").default}
                    alt="afd"
                    className="h-16 mt-4"
                  />
                </Space>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="w-full text-center">
                <h1 className="text-blue-900 font-extrabold italic text-4xl pt-8 pb-2 ">
                  PARTNERS
                </h1>
                <MyDivider />
                <div className="flex justify-center">
                  <Space size="large">
                    <img
                      src={require("../../assets/images/alogo.webp").default}
                      alt="afd"
                      className="h-16 mt-4"
                    />
                    <img
                      src={require("../../assets/images/sirius.png").default}
                      alt="afd"
                      className="h-16 mt-4"
                    />
                    <img
                      src={require("../../assets/images/wisdom.png").default}
                      alt="afd"
                      className="h-16 mt-4"
                    />
                  </Space>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
