// import { CopyOutlined } from "@ant-design/icons";
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
// import {
//   Button,
//   Col,
//   Form,
//   Input,
//   InputNumber,
//   Modal,
//   notification,
//   Radio,
//   Row,
//   Select,
// } from "antd";
// import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
// import React, { useCallback, useEffect, useState } from "react";
// import CopyToClipboard from "react-copy-to-clipboard";
// import { AdminService } from "src/resources/AdminService";
// import { GlobalVariables } from "src/resources/GlobalVariables";
// import MyDivider from "./MyDivider";
// import ShareLinks from "./ShareLinks";

// const Register = () => {
//   const [form] = Form.useForm();
//   const [eventType, seteventType] = useState(null);
//   const [value, setValue] = useState(null);
//   const [km, setKm] = useState(null);
//   const stripe = useStripe();
//   const elements = useElements();
//   const [goal, setGoal] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [furl, setFurl] = useState(null);
//   const [copied, setCopied] = useState(false);
//   const { lg } = useBreakpoint();
//   const kms = [
//     [60, 20, 5],
//     [10, 5],
//     [10, 5],
//   ];
//   const states = ["Sydney", "Melbourne", "Adelaide"];
//   useEffect(() => {
//     form.setFieldsValue({ total: value });
//   }, [form, value]);
//   useEffect(() => {
//     form.setFieldsValue({ hedef: goal });
//   }, [form, goal]);
//   const eventChange = (v) => {
//     console.log(v);
//     seteventType(v);
//     setKm(null);
//     form.setFieldsValue({ km: null });
//   };
//   const kmChange = (v) => {
//     console.log(v);
//     setKm(v);
//     switch (eventType) {
//       case 1:
//         setGoal(v === 60 ? 200 : 100);
//         break;
//       case 2:
//         setGoal(v === 10 ? 100 : 50);
//         break;
//       case 3:
//         setGoal(50);
//         break;
//       default:
//         break;
//     }
//   };
//   const handleSubmit = useCallback(
//     async function (values) {
//       //    event.preventDefault();
//       console.log(values);
//       setLoading(true);
//       if (!stripe || !elements) {
//         setLoading(false);
//         return;
//       }

//       try {
//         const cardElement = elements.getElement(CardElement);

//         const { error, paymentMethod } = await stripe.createPaymentMethod({
//           type: "card",
//           card: cardElement,
//         });

//         if (error) {
//           setLoading(false);
//           return;
//         }

//         const obj = {
//           ...values,
//           total: values.total + 20,
//           goal,
//           age18: true,
//         };
//         console.log("obj:", obj);
//         let response = await AdminService.finalizeOrder({
//           ...obj,
//           payment: {
//             payment_method_id: paymentMethod.id,
//           },
//         });
//         // 3D Secure
//         while (response.status === 402) {
//           const { paymentIntent, error } = await stripe.handleCardAction(
//             response.data.token
//           );
//           if (error) {
//             setLoading(false);
//             // unable to pass 3Ds
//             return;
//           }

//           response = await AdminService.finalizeOrder({
//             ...obj,
//             payment: {
//               payment_intent_id: paymentIntent.id,
//             },
//           });
//         }

//         const ok = response.status === 200 || response.status === 204;
//         if (ok) {
//           notification.success({
//             message: "Success!",
//             description: "Thank you for your payment!",
//           });
//           setValue(null);
//           elements.getElement(CardElement).clear();
//           form.resetFields();
//           setFurl(response.data.url);
//           console.log("gelen data", response.data);
//         } else {
//           setLoading(false);
//         }
//       } finally {
//         setLoading(false);
//       }
//     },
//     [stripe, elements, goal, form]
//   );
//   return (
//     <div className="bg-sari">
//       <Modal
//         title="Registration Completed"
//         visible={furl !== null}
//         onCancel={() => setFurl(null)}
//         onOk={() => setFurl(null)}
//         closable
//       >
//         <p className="text-sky-500 text-xl font-semibold">Congratulations!</p>
//         <p className="text-lg">You have registered for this event.</p>
//         <p className="text-lg">Your foundraiser link is :</p>
//         <Button type="link" onClick={() => window.open(furl, "_blank")}>
//           {furl}
//         </Button>
//         {copied ? (
//           <span>Copied!</span>
//         ) : (
//           <span>
//             <CopyToClipboard text={furl} onCopy={() => setCopied(true)}>
//               <Button type="primary" ghost size="small" icon={<CopyOutlined />}>
//                 Copy
//               </Button>
//             </CopyToClipboard>
//           </span>
//         )}
//         <br />
//         <br />
//         <ShareLinks shareUrl={furl} />
//       </Modal>
//       <div className="mx-auto max-w-7xl p-2 lg:-mt-0 -mt-40">
//         <Row gutter={[24, 24]} align="middle">
//           <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
//             <img
//               src={require("../../assets/images/register.jpg").default}
//               alt="register"
//               className="max-w-full w-full border-2 border-gray-300 rounded-2xl shadow-2xl"
//             />
//           </Col>
//           <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
//             <div>
//               <h1 className="text-blue-900 text-3xl lg:text-4xl font-extrabold  uppercase mb-1 text-center">
//                 REGISTRATION FORM
//               </h1>
//               <MyDivider />
//               <br />
//               <br />
//               <Form
//                 form={form}
//                 size="large"
//                 {...GlobalVariables.formLayout}
//                 onFinish={handleSubmit}
//               >
//                 <Form.Item
//                   label="City"
//                   name="state"
//                   rules={[{ required: true, message: "City is required!" }]}
//                 >
//                   <Select placeholder="Please select city">
//                     {states.map((s) => (
//                       <Select.Option key={s} value={s}>
//                         {s}
//                       </Select.Option>
//                     ))}
//                   </Select>
//                 </Form.Item>
//                 <Form.Item
//                   label="First Name"
//                   name="firstname"
//                   rules={[
//                     { required: true, message: "First name is required!" },
//                   ]}
//                 >
//                   <Input placeholder="Please type your firstname" />
//                 </Form.Item>
//                 <Form.Item
//                   label="Last Name"
//                   name="lastname"
//                   rules={[
//                     { required: true, message: "Last name is required!" },
//                   ]}
//                 >
//                   <Input placeholder="Please type your lastname" />
//                 </Form.Item>
//                 <Form.Item
//                   label="Email Address"
//                   name="email"
//                   rules={[{ required: true, message: "Email is required!" }]}
//                 >
//                   <Input placeholder="Please type your email" />
//                 </Form.Item>
//                 <Form.Item label="Your phone (opt.)" name="phone">
//                   <Input placeholder="Please type your phone" />
//                 </Form.Item>
//                 <Form.Item
//                   label="Event choice "
//                   name="eventType"
//                   rules={[
//                     { required: true, message: "Event choice is required!" },
//                   ]}
//                 >
//                   <Select
//                     onChange={eventChange}
//                     placeholder="Please select your choice"
//                   >
//                     <Select.Option key="0" value={1}>
//                       Cycling
//                     </Select.Option>
//                     <Select.Option key="1" value={2}>
//                       Running
//                     </Select.Option>
//                     <Select.Option key="2" value={3}>
//                       Walking
//                     </Select.Option>
//                   </Select>
//                 </Form.Item>
//                 {eventType && (
//                   <Form.Item
//                     label="Target Km "
//                     name="targetkm"
//                     rules={[
//                       { required: true, message: "Target km is required!" },
//                     ]}
//                   >
//                     <Select
//                       onChange={kmChange}
//                       placeholder="Please select your target km"
//                     >
//                       {kms[eventType - 1].map((e) => (
//                         <Select.Option key={e} value={e}>
//                           {e} km
//                         </Select.Option>
//                       ))}
//                     </Select>
//                   </Form.Item>
//                 )}
//                 {km && (
//                   <>
//                     <Form.Item label="Registration Fee" className="-mt-4">
//                       <span className="font-bold text-lg"> $20</span>
//                     </Form.Item>
//                     <Form.Item
//                       label="Your Donation Goal"
//                       className="-mt-4"
//                       name="hedef"
//                       help={`Min value for this event $${goal}. You can type more value`}
//                     >
//                       <InputNumber
//                         defaultValue={goal}
//                         min={goal}
//                         max={10000}
//                         className=" disabled:text-black font-bold text-lg"
//                         addonBefore="$"
//                       />
//                     </Form.Item>
//                     <Form.Item
//                       wrapperCol={
//                         lg
//                           ? {
//                               offset: 6,
//                               span: 18,
//                             }
//                           : {}
//                       }
//                     >
//                       <p className="text-base">
//                         Would you like to donate for your goal?
//                       </p>
//                     </Form.Item>
//                     <Form.Item
//                       label={
//                         <span>
//                           Your Donation
//                           <br />
//                           For E. Victims
//                         </span>
//                       }
//                       name="total"
//                       className="-mt-6"
//                     >
//                       <Radio.Group
//                         value={value}
//                         buttonStyle="solid"
//                         style={{ marginBottom: 16 }}
//                         onChange={(a) => setValue(a.target.value)}
//                       >
//                         {[20, 50, 100, 150].map((s) => (
//                           <Radio.Button key={s} value={s}>
//                             ${s}
//                           </Radio.Button>
//                         ))}
//                       </Radio.Group>
//                       <InputNumber
//                         style={{ width: "200px" }}
//                         min={0}
//                         max={10000}
//                         placeholder="Or type your value"
//                         onChange={(a) => setValue(a)}
//                         value={value}
//                         addonBefore="$"
//                       />
//                     </Form.Item>
//                     <Form.Item
//                       wrapperCol={
//                         lg
//                           ? {
//                               offset: 6,
//                               span: 18,
//                             }
//                           : {}
//                       }
//                     >
//                       <h4 className="text-base">
//                         Please Type Your Credit/Debit Card Details
//                       </h4>
//                       <CardElement
//                         options={{
//                           style: {
//                             base: {
//                               fontSize: "18px",
//                               fontFamily: "monospace",
//                               color: "#424770",
//                               "::placeholder": {
//                                 color: "#aab7c4",
//                               },
//                             },
//                             invalid: {
//                               color: "red",
//                             },
//                           },
//                         }}
//                         className="st-form"
//                       />
//                       <Button
//                         loading={loading}
//                         type="primary"
//                         htmlType="submit"
//                         disabled={!stripe || !elements}
//                         block
//                         style={{ maxWidth: "500px" }}
//                         size="large"
//                         className="text-lg h-14 leading-3"
//                       >
//                         Pay ${value + 20} & Register Now
//                       </Button>
//                     </Form.Item>{" "}
//                   </>
//                 )}
//               </Form>
//               {km && (
//                 <img
//                   src={require("../../assets/images/secure-stripe.png").default}
//                   alt="secure"
//                   className="w-full max-w-full"
//                 />
//               )}
//             </div>
//           </Col>
//         </Row>
//       </div>
//       <br />
//       <br />
//       <br />
//       <br />
//     </div>
//   );
// };

import React from "react";

const Register = () => {
  return (
    <div className="justify-center text-center p-10">Registration Closed!</div>
  );
};

export default Register;
