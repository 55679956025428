export const AfghanDonations = [
  { key: 9, id: 9, fullname: "Kerami Goz", slug: "kerami-goz-9", total: 1850 },
  {
    key: 30,
    id: 30,
    fullname: "Rabia Ceylan",
    slug: "rabia-ceylan-30",
    total: 1470,
  },
  {
    key: 8,
    id: 8,
    fullname: "Mehmet Saral",
    slug: "mehmet-saral-8",
    total: 1245,
  },
  {
    key: 6,
    id: 6,
    fullname: "Mustafa Kohistani",
    slug: "mustafa-kohistani-6",
    total: 830,
  },
  {
    key: 13,
    id: 13,
    fullname: "Bilal WAHEED",
    slug: "bilal-waheed-13",
    total: 460,
  },
  {
    key: 22,
    id: 22,
    fullname: "Margaret Turnbull ",
    slug: "margaret-turnbull-22",
    total: 460,
  },
  {
    key: 36,
    id: 36,
    fullname: "Desiree  Leone",
    slug: "desiree-leone-36",
    total: 300,
  },
  {
    key: 68,
    id: 68,
    fullname: "Nazan Polat",
    slug: "nazan-polat-68",
    total: 290,
  },
  {
    key: 17,
    id: 17,
    fullname: "Gulumser Goz",
    slug: "gulumser-goz-17",
    total: 280,
  },
  {
    key: 16,
    id: 16,
    fullname: "Figen Yavuz",
    slug: "figen-yavuz-16",
    total: 260,
  },
  {
    key: 31,
    id: 31,
    fullname: "Farid  Faiz",
    slug: "farid-faiz-31",
    total: 240,
  },
  {
    key: 50,
    id: 50,
    fullname: "Rebecca Langton",
    slug: "rebecca-langton-50",
    total: 190,
  },
  {
    key: 66,
    id: 66,
    fullname: "Huseyin  Kizmaz",
    slug: "huseyin-kizmaz-66",
    total: 180,
  },
  {
    key: 10,
    id: 10,
    fullname: "Mehmet Ozalp",
    slug: "mehmet-ozalp-10",
    total: 170,
  },
  {
    key: 27,
    id: 27,
    fullname: "Tamer Kahil",
    slug: "tamer-kahil-27",
    total: 150,
  },
  {
    key: 63,
    id: 63,
    fullname: "Wael El-Asmar",
    slug: "wael-el-asmar-63",
    total: 150,
  },
  {
    key: 15,
    id: 15,
    fullname: "Recep Aydogan",
    slug: "recep-aydogan-15",
    total: 150,
  },
  {
    key: 21,
    id: 21,
    fullname: "Jafar Noor",
    slug: "jafar-noor-21",
    total: 125,
  },
  {
    key: 55,
    id: 55,
    fullname: "Mungun-Erdene Tsogtoo",
    slug: "mungun-erdene-tsogtoo-55",
    total: 120,
  },
  {
    key: 11,
    id: 11,
    fullname: "Matthew Phillips",
    slug: "matthew-phillips-11",
    total: 111,
  },
  {
    key: 35,
    id: 35,
    fullname: "Gurkan Gurbuz",
    slug: "gurkan-gurbuz-35",
    total: 100,
  },
  {
    key: 69,
    id: 69,
    fullname: "Osman  KAROLIA ",
    slug: "osman-karolia-69",
    total: 100,
  },
  {
    key: 62,
    id: 62,
    fullname: "Gill Pennington",
    slug: "gill-pennington-62",
    total: 100,
  },
  {
    key: 25,
    id: 25,
    fullname: "Patrick Mahony",
    slug: "patrick-mahony-25",
    total: 100,
  },
  {
    key: 56,
    id: 56,
    fullname: "Azranur SARAL",
    slug: "azranur-saral-56",
    total: 100,
  },
  {
    key: 40,
    id: 40,
    fullname: "Paul Power",
    slug: "paul-power-40",
    total: 100,
  },
  {
    key: 41,
    id: 41,
    fullname: "Veronica Power",
    slug: "veronica-power-41",
    total: 100,
  },
  {
    key: 49,
    id: 49,
    fullname: "Yasser Waheed",
    slug: "yasser-waheed-49",
    total: 80,
  },
  {
    key: 23,
    id: 23,
    fullname: "Emanuel de Sousa",
    slug: "emanuel-de-sousa-23",
    total: 75,
  },
  { key: 28, id: 28, fullname: "Akadir Bul", slug: "akadir-bul-28", total: 70 },
  {
    key: 34,
    id: 34,
    fullname: "Serkan Iner",
    slug: "serkan-iner-34",
    total: 70,
  },
  {
    key: 73,
    id: 73,
    fullname: "Chris Vosnakis",
    slug: "chris-vosnakis-73",
    total: 60,
  },
  {
    key: 20,
    id: 20,
    fullname: "Emine Buber",
    slug: "emine-buber-20",
    total: 50,
  },
  {
    key: 43,
    id: 43,
    fullname: "Johanna Power",
    slug: "johanna-power-43",
    total: 50,
  },
  {
    key: 12,
    id: 12,
    fullname: "Firaz Osman",
    slug: "firaz-osman-12",
    total: 50,
  },
  {
    key: 14,
    id: 14,
    fullname: "Zarlasht  Dardmal ",
    slug: "zarlasht-dardmal-14",
    total: 50,
  },
  {
    key: 33,
    id: 33,
    fullname: "Khalil Nasri",
    slug: "khalil-nasri-33",
    total: 50,
  },
  {
    key: 42,
    id: 42,
    fullname: "Clare Power",
    slug: "clare-power-42",
    total: 50,
  },
  {
    key: 46,
    id: 46,
    fullname: "Zehra  Yalcin ",
    slug: "zehra-yalcin-46",
    total: 50,
  },
  {
    key: 57,
    id: 57,
    fullname: "ZAFER POLAT",
    slug: "zafer-polat-57",
    total: 50,
  },
  { key: 67, id: 67, fullname: "omer ozgun", slug: "omer-ozgun-67", total: 50 },
  { key: 71, id: 71, fullname: "Emma Carr", slug: "emma-carr-71", total: 50 },
  {
    key: 74,
    id: 74,
    fullname: "Meryem Ozalp",
    slug: "meryem-ozalp-74",
    total: 40,
  },
  {
    key: 61,
    id: 61,
    fullname: "faiza Shakori",
    slug: "faiza-shakori-61",
    total: 30,
  },
  { key: 18, id: 18, fullname: "Ekrem Goz", slug: "ekrem-goz-18", total: 30 },
  {
    key: 48,
    id: 48,
    fullname: "Adama  Kamara ",
    slug: "adama-kamara-48",
    total: 30,
  },
  { key: 26, id: 26, fullname: "Emre Goz", slug: "emre-goz-26", total: 30 },
  {
    key: 64,
    id: 64,
    fullname: "Ali Attayee",
    slug: "ali-attayee-64",
    total: 20,
  },
  {
    key: 65,
    id: 65,
    fullname: "Ariel Yahya",
    slug: "ariel-yahya-65",
    total: 20,
  },
  {
    key: 29,
    id: 29,
    fullname: "Nataliia Pyhor",
    slug: "nataliia-pyhor-29",
    total: 20,
  },
  {
    key: 51,
    id: 51,
    fullname: "Mahsa Javam",
    slug: "mahsa-javam-51",
    total: 20,
  },
  {
    key: 52,
    id: 52,
    fullname: "Meryem Aydogan",
    slug: "meryem-aydogan-52",
    total: 20,
  },
  {
    key: 53,
    id: 53,
    fullname: "Najib Nasri",
    slug: "najib-nasri-53",
    total: 20,
  },
  {
    key: 54,
    id: 54,
    fullname: "Ahmet Rufai Aslan",
    slug: "ahmet-rufai-aslan-54",
    total: 20,
  },
  {
    key: 70,
    id: 70,
    fullname: "Salima Karolia",
    slug: "salima-karolia-70",
    total: 0.0,
  },
  {
    key: 76,
    id: 76,
    fullname: "Vedat Koca",
    slug: "vedat-koca-76",
    total: 0.0,
  },
  {
    key: 39,
    id: 39,
    fullname: "Mehmet Koca",
    slug: "mehmet-koca-39",
    total: 0.0,
  },
  { key: 72, id: 72, fullname: "Reem Khan", slug: "reem-khan-72", total: 0.0 },
  {
    key: 44,
    id: 44,
    fullname: "Hilal YALCIN ",
    slug: "hilal-yalcin-44",
    total: 0.0,
  },
  {
    key: 45,
    id: 45,
    fullname: "Zeynep Gozaydin",
    slug: "zeynep-gozaydin-45",
    total: 0.0,
  },
  {
    key: 24,
    id: 24,
    fullname: "Muhamed Cengic",
    slug: "muhamed-cengic-24",
    total: 0.0,
  },
  {
    key: 38,
    id: 38,
    fullname: "Mehmet Ramazan Yavuz",
    slug: "mehmet-ramazan-yavuz-38",
    total: 0.0,
  },
  {
    key: 37,
    id: 37,
    fullname: "Thomas Wright",
    slug: "thomas-wright-37",
    total: 0.0,
  },
  { key: 32, id: 32, fullname: "Ali Javam", slug: "ali-javam-32", total: 0.0 },
  {
    key: 58,
    id: 58,
    fullname: "Mustafa Abay",
    slug: "mustafa-abay-58",
    total: 0.0,
  },
  { key: 59, id: 59, fullname: "Rosa Sota", slug: "rosa-sota-59", total: 0.0 },
  {
    key: 60,
    id: 60,
    fullname: "Misky  Sota Leone ",
    slug: "misky-sota-leone-60",
    total: 0.0,
  },
  {
    key: 47,
    id: 47,
    fullname: "Dativah Nitsios",
    slug: "dativah-nitsios-47",
    total: 0.0,
  },
  { key: 19, id: 19, fullname: "Emma Carr", slug: "emma-carr-19", total: 0.0 },
  {
    key: 75,
    id: 75,
    fullname: "Almila Koca",
    slug: "almila-koca-75",
    total: 0.0,
  },
  {
    key: 7,
    id: 7,
    fullname: "Halit Yazici",
    slug: "halit-yazici-7",
    total: 0.0,
  },
  {
    key: 5,
    id: 5,
    fullname: "Sedat Yazici",
    slug: "sedat-yazici-5",
    total: 0.0,
  },
];
