export const GreeceDonations = [
  {
    key: 79,
    id: 79,
    fullname: "Mehmet Saral",
    slug: "mehmet-saral-79",
    state: "Sydney",
    total: 2750,
  },
  {
    key: 82,
    id: 82,
    fullname: "Ozgur Ozdemir",
    slug: "ozgur-ozdemir-82",
    state: "Brisbane",
    total: 2390,
  },
  {
    key: 118,
    id: 118,
    fullname: "Huseyin Kizmaz",
    slug: "huseyin-kizmaz-118",
    state: "Sydney",
    total: 1370,
  },
  {
    key: 78,
    id: 78,
    fullname: "Kerami  Goz",
    slug: "kerami-gpz-78",
    state: "Sydney",
    total: 1100,
  },
  {
    key: 110,
    id: 110,
    fullname: "Emre A",
    slug: "emre-a-110",
    state: "Melbourne",
    total: 1041,
  },
  {
    key: 84,
    id: 84,
    fullname: "Abdul Celil Gelim",
    slug: "abdul-celil-gelim-84",
    state: "Melbourne",
    total: 620,
  },
  {
    key: 100,
    id: 100,
    fullname: "Ahmet Keskin",
    slug: "ahmet-keskin-100",
    state: "Melbourne",
    total: 600,
  },
  {
    key: 101,
    id: 101,
    fullname: "Omer Ayvaz",
    slug: "omer-ayvaz-101",
    state: "Sydney",
    total: 500,
  },
  {
    key: 105,
    id: 105,
    fullname: "Gurkan Gurbuz",
    slug: "gurkan-gurbuz-105",
    state: "Sydney",
    total: 350,
  },
  {
    key: 134,
    id: 134,
    fullname: "Hannia  Rawas",
    slug: "hannia-rawas-134",
    state: "Melbourne",
    total: 320,
  },
  {
    key: 106,
    id: 106,
    fullname: "Mogi Tsogtoo",
    slug: "mogi-tsogtoo-106",
    state: "Sydney",
    total: 320,
  },
  {
    key: 116,
    id: 116,
    fullname: "Zakariya Sari",
    slug: "zakariya-sari-116",
    state: "Sydney",
    total: 250,
  },
  {
    key: 83,
    id: 83,
    fullname: "Mohammad Siaj",
    slug: "mohammad-siaj-83",
    state: "Sydney",
    total: 250,
  },
  {
    key: 139,
    id: 139,
    fullname: "Mohammed  Azad",
    slug: "mohammed-azad-139",
    state: "Sydney",
    total: 250,
  },
  {
    key: 87,
    id: 87,
    fullname: "Matthew Phillips ",
    slug: "matthew-phillips-87",
    state: "Sydney",
    total: 202,
  },
  {
    key: 77,
    id: 77,
    fullname: "Mas  Asa",
    slug: "mas-asa-77",
    state: "Sydney",
    total: 200,
  },
  {
    key: 122,
    id: 122,
    fullname: "Mehmet Ozalp",
    slug: "mehmet-ozalp-122",
    state: "Sydney",
    total: 150,
  },
  {
    key: 91,
    id: 91,
    fullname: "Nia Karteris",
    slug: "nia-karteris-91",
    state: "Sydney",
    total: 150,
  },
  {
    key: 104,
    id: 104,
    fullname: "Zafer Polat",
    slug: "zafer-polat-104",
    state: "Sydney",
    total: 120,
  },
  {
    key: 99,
    id: 99,
    fullname: "Bas Hagemrad",
    slug: "bas-hagemrad-99",
    state: "Sydney",
    total: 120,
  },
  {
    key: 149,
    id: 149,
    fullname: "Azizul Awang",
    slug: "azizul-awang-149",
    state: "Melbourne",
    total: 120,
  },
  {
    key: 112,
    id: 112,
    fullname: "Mete Samci",
    slug: "mete-samci-112",
    state: "Melbourne",
    total: 120,
  },
  {
    key: 93,
    id: 93,
    fullname: "Mustafa Kadioglu",
    slug: "mustafa-kadioglu-93",
    state: "Melbourne",
    total: 100,
  },
  {
    key: 126,
    id: 126,
    fullname: "Aaqib  Suhale",
    slug: "aaqib-suhale-126",
    state: "Melbourne",
    total: 100,
  },
  {
    key: 129,
    id: 129,
    fullname: "Cengiz Altinors",
    slug: "cengiz-altinors-129",
    state: "Melbourne",
    total: 100,
  },
  {
    key: 98,
    id: 98,
    fullname: "mohamed hosny",
    slug: "mohamed-hosny-98",
    state: "Sydney",
    total: 100,
  },
  {
    key: 125,
    id: 125,
    fullname: "Wael El-Asmar",
    slug: "wael-el-asmar-125",
    state: "Sydney",
    total: 100,
  },
  {
    key: 123,
    id: 123,
    fullname: "Recep Aydogan",
    slug: "recep-aydogan-123",
    state: "Sydney",
    total: 100,
  },
  {
    key: 97,
    id: 97,
    fullname: "Tamer Kahil",
    slug: "tamer-kahil-97",
    state: "Sydney",
    total: 100,
  },
  {
    key: 124,
    id: 124,
    fullname: "Harun Rashid",
    slug: "harun-rashid-124",
    state: "Sydney",
    total: 80,
  },
  {
    key: 119,
    id: 119,
    fullname: "AHMET RUFAI ASLAN",
    slug: "ahmet-rufai-aslan-119",
    state: "Sydney",
    total: 70,
  },
  {
    key: 88,
    id: 88,
    fullname: "Figen Yavuz",
    slug: "figen-yavuz-88",
    state: "Sydney",
    total: 70,
  },
  {
    key: 92,
    id: 92,
    fullname: "Gulumser Goz",
    slug: "gulumser-goz-92",
    state: "Sydney",
    total: 70,
  },
  {
    key: 135,
    id: 135,
    fullname: "Julian Lobato",
    slug: "julian-lobato-135",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 137,
    id: 137,
    fullname: "emre demir",
    slug: "emre-demir-137",
    state: "Sydney",
    total: 50,
  },
  {
    key: 138,
    id: 138,
    fullname: "Nesrin  Kurucu",
    slug: "nesrin-kurucu-138",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 80,
    id: 80,
    fullname: "Mustafa Abay",
    slug: "mustafa-abay-80",
    state: "Sydney",
    total: 50,
  },
  {
    key: 95,
    id: 95,
    fullname: "Firaz Osman",
    slug: "firaz-osman-95",
    state: "Sydney",
    total: 50,
  },
  {
    key: 94,
    id: 94,
    fullname: "Bilal Dabboucy",
    slug: "bilal-dabboucy-94",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 150,
    id: 150,
    fullname: "Nazan Polat",
    slug: "nazan-polat-150",
    state: "Sydney",
    total: 50,
  },
  {
    key: 89,
    id: 89,
    fullname: "Ramazan  Yavuz",
    slug: "ramazan-yavuz-89",
    state: "Sydney",
    total: 50,
  },
  {
    key: 85,
    id: 85,
    fullname: "Sedat Y",
    slug: "sedat-yazici-85",
    state: "Sydney",
    total: 50,
  },
  {
    key: 128,
    id: 128,
    fullname: "Zekai  Ergun ",
    slug: "zekai-ergun-128",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 111,
    id: 111,
    fullname: "Tarek Sari",
    slug: "tarek-sari-111",
    state: "Sydney",
    total: 50,
  },
  {
    key: 130,
    id: 130,
    fullname: "Aylin Altinors",
    slug: "aylin-altinors-130",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 131,
    id: 131,
    fullname: "muneeb hayat",
    slug: "muneeb-hayat-131",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 132,
    id: 132,
    fullname: "Ahmed Albayrak",
    slug: "ahmed-albayrak-132",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 121,
    id: 121,
    fullname: "Mustafa Kayacik",
    slug: "mustafa-kayacik-121",
    state: "Sydney",
    total: 50,
  },
  {
    key: 90,
    id: 90,
    fullname: "Emir Beytur",
    slug: "emir-beytur-90",
    state: "Melbourne",
    total: 30,
  },
  {
    key: 141,
    id: 141,
    fullname: "Saza Rwandzy",
    slug: "saza-rwandzy-141",
    state: "Melbourne",
    total: 20,
  },
  {
    key: 81,
    id: 81,
    fullname: "MESUT BUBER",
    slug: "mesut-buber-81",
    state: "Sydney",
    total: 20,
  },
  {
    key: 103,
    id: 103,
    fullname: "Chris Cuevas",
    slug: "chris-cuevas-103",
    state: "Sydney",
    total: 20,
  },
  {
    key: 136,
    id: 136,
    fullname: "Yucel Ugurlu",
    slug: "yucel-ugurlu-136",
    state: "Sydney",
    total: 20,
  },
  {
    key: 102,
    id: 102,
    fullname: "Semiha Durmus ",
    slug: "semiha-durmus-102",
    state: "Sydney",
    total: 20,
  },
  {
    key: 145,
    id: 145,
    fullname: "Kevin Wh",
    slug: "kevin-wh-145",
    state: "Sydney",
    total: 20,
  },
  {
    key: 146,
    id: 146,
    fullname: "Serhat Filiz",
    slug: "serhat-filiz-146",
    state: "Melbourne",
    total: 20,
  },
  {
    key: 96,
    id: 96,
    fullname: "Bilal Waheed",
    slug: "bilal-waheed-96",
    state: "Sydney",
    total: 20,
  },
  {
    key: 107,
    id: 107,
    fullname: "Malek Elzein",
    slug: "malek-elzein-107",
    state: "Sydney",
    total: 20,
  },
  {
    key: 143,
    id: 143,
    fullname: "Sina Fadaei",
    slug: "sina-fadaei-143",
    state: "Brisbane",
    total: 1,
  },
  {
    key: 152,
    id: 152,
    fullname: "FATIH KARABIYIK",
    slug: "fatih-karabiyik-152",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 115,
    id: 115,
    fullname: "Insaf Sari",
    slug: "insaf-sari-115",
    state: "Sydney",
    total: 0,
  },
  {
    key: 117,
    id: 117,
    fullname: "Yasmine Sari",
    slug: "yasmine-sari-117",
    state: "Sydney",
    total: 0,
  },
  {
    key: 109,
    id: 109,
    fullname: "Ali Javam",
    slug: "ali-javam-109",
    state: "Sydney",
    total: 0,
  },
  {
    key: 120,
    id: 120,
    fullname: "SALIH ALTUNTAS",
    slug: "salih-altuntas-120",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 108,
    id: 108,
    fullname: "Muhammed Akyuz",
    slug: "muhammed-akyuz-108",
    state: "Sydney",
    total: 0,
  },
  {
    key: 127,
    id: 127,
    fullname: "Zekai  Ergun ",
    slug: "zekai-ergun-127",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 113,
    id: 113,
    fullname: "Kerime Y",
    slug: "kerime-yazici-113",
    state: "Sydney",
    total: 0,
  },
  {
    key: 133,
    id: 133,
    fullname: "Ahmed Elmohandis ",
    slug: "ahmed-elmohandis-133",
    state: "Brisbane",
    total: 0,
  },
  {
    key: 140,
    id: 140,
    fullname: "Ilhan Eroglu",
    slug: "ilhan-eroglu-140",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 142,
    id: 142,
    fullname: "Ali Fadaei",
    slug: "ali-fadaei-142",
    state: "Brisbane",
    total: 0,
  },
  {
    key: 144,
    id: 144,
    fullname: "Ibrahim Ulusoy",
    slug: "ibrahim-ulusoy-144",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 147,
    id: 147,
    fullname: "AJ Ryadh",
    slug: "aj-ryadh-147",
    state: "Sydney",
    total: 0,
  },
  {
    key: 148,
    id: 148,
    fullname: "Azizul Awang",
    slug: "azizul-awang-148",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 86,
    id: 86,
    fullname: "Halit Y",
    slug: "halit-yazici-86",
    state: "Sydney",
    total: 0,
  },
  {
    key: 151,
    id: 151,
    fullname: "Athiah Abd Samat",
    slug: "athiah-abd-samat-151",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 114,
    id: 114,
    fullname: "Mariam El Masri",
    slug: "mariam-el-masri-114",
    state: "Sydney",
    total: 0,
  },
];
