import { Col, Image, Row } from "antd";
import React from "react";
import { useHistory } from "react-router";
import MyDivider from "./MyDivider";

const BannerTurkiye = () => {
  const router = useHistory();
  return (
    <div
      style={{
        backgroundColor: "#F29F05",
        zIndex: 9999999,
      }}
      className="-mt-40  lg:mt-0 pb-4"
    >
      <div className=" mx-auto lg:ml-2">
        <Row gutter={[16, 16]} align="top">
          <Col
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="bg-sari w-full"
          >
            {/* <h1 className="text-blue-900 font-extrabold italic text-4xl pt-8 pb-2 text-center">
              ORGANISED BY
            </h1> 
            <MyDivider />*/}
            <div className="p-4 text-center border-2 shadow-lg border-white m-2 lg:m-4 bg-white bg-opacity-50 ">
              <img
                src={require("../../assets/images/afd.png").default}
                alt="afd"
                className="mx-auto h-20 mb-4 mt-2"
              />
              <h1 className="text-blue-900 font-bold  text-3xl uppercase mb-1">
                Thanks everyone
              </h1>
              <MyDivider />
              <div className="!text-base text-left  space-y-6">
                <br />
                <h1 className="font-bold text-xl text-red-600">
                  {" "}
                  $20,000 Raised for Earthquake Victims in Turkey and Syria
                </h1>{" "}
                <p>
                  We would like to express our heartfelt gratitude to everyone
                  who participated in and supported our fundraising campaign
                  called "Run.Walk.Ride" in Sydney, Melbourne, and Adelaide on
                  Sunday to aid earthquake victims in Turkey and Syria. Special
                  thanks to the generosity and support of our participants,
                  volunteers, and partner organizations such as the Australian
                  Relief Organisation (ARO), Amity College, and Galaxy
                  Foundation. We would also like to thank Liverpool Councillor
                  Nathan Hagarty for joining our fundraising campaign.
                </p>
                <p>
                  At the end of the day, we were able to raise an incredible
                  $20,000 to provide essential items such as food, water, and
                  shelter to those affected by this tragedy. Your contributions
                  have made a significant impact and have provided hope and
                  relief to those who have lost so much. We could not have
                  achieved this success without your help, and we are immensely
                  grateful for your support. Thank you again for being a part of
                  this effort.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="p-2 lg:pr-4 space-y-4 text-center">
              <Image
                src="https://api.advocatesfordignity.org.au/images/cb917c-WhatsAppImage20230318at9.22.19PM.jpeg"
                alt="banner"
                className="w-full max-w-3xl border-4 border-white mr-4 shadow-xl rounded"
              />
              <Image
                src="https://api.advocatesfordignity.org.au/images/57a84e-WhatsAppImage20230318at10.24.15PM.jpeg"
                alt="banner"
                className="w-full max-w-3xl border-4 border-white mr-4 shadow-xl rounded"
              />
              <Image
                src="https://api.advocatesfordignity.org.au/images/925d7f-WhatsAppImage20230318at9.32.26PM.jpeg"
                alt="banner"
                className="w-full max-w-3xl border-4 border-white mr-4 shadow-xl rounded"
              />
              <Image
                src="https://api.advocatesfordignity.org.au/images/fa3bd1-WhatsAppImage20230318at9.22.31PM.jpeg"
                alt="banner"
                className="w-full max-w-3xl border-4 border-white mr-4 shadow-xl rounded"
              />
              <Image
                src="https://api.advocatesfordignity.org.au/images/095756-WhatsAppImage20230318at9.22.40PM.jpeg"
                alt="banner"
                className="w-full max-w-3xl border-4 border-white mr-4 shadow-xl rounded"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BannerTurkiye;
