import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row, Tag } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const PastEvents = () => {
  const router = useHistory();
  return (
    <div
      style={{
        backgroundColor: "#F29F05",
        zIndex: 9999999,
      }}
      className="-mt-40  lg:mt-0 pb-4"
    >
      <div className="max-w-7xl mx-auto pt-4 min-h-screen">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image
              className="w-full shadow-xl"
              src={require("../../assets/images/raw-past.jpg").default}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="mx-auto text-base max-w-prose  lg:max-w-none lg:pl-6 p-2">
              <div>
                <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
                  <Tag color="red">PAST EVENT</Tag>
                  <Tag color="blue">06/02/2022</Tag>
                </h2>
                <h3 className="mt-2 text-3xl leading-8 font-bold  text-f5f5f5 sm:text-4xl ">
                  Run.Walk.Ride.
                  <br /> For Afghan Refugees
                </h3>
              </div>
              <p className="text-blue-800 text-lg !mt-2">
                The Advocates for Dignity (AFD) have organised a run, walk and
                ride activity to raise funds for advocacy work that the Refugee
                Council of Australia (RCOA) will undertake for Australia’s
                engagement in Afghanistan.
              </p>
              <br />
              <Button
                type="primary"
                size="large"
                icon={<ArrowRightOutlined />}
                onClick={() => router.push("/home/past-afghan-refugees")}
              >
                Get Details
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image
              className="w-full shadow-xl"
              src={require("../../assets/images/past-greece.jpg").default}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="mx-auto text-base max-w-prose  lg:max-w-none lg:pl-6 p-2">
              <div>
                <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
                  <Tag color="red">PAST EVENT</Tag>
                  <Tag color="blue">26/06/2022</Tag>
                </h2>
                <h3 className="mt-2 text-3xl leading-8 font-bold  text-f5f5f5 sm:text-4xl ">
                  Run.Walk.Ride.
                  <br /> For Refugees in Greece
                </h3>
              </div>
              <p className="text-blue-800 text-lg !mt-2">
                Through the funds raised during this activity, Advocates for
                Dignity (AFD) will help the Australian Relief Organisation (ARO)
                to raise the much needed funds for the refugees in Greece.
              </p>
              <br />
              <Button
                type="primary"
                size="large"
                icon={<ArrowRightOutlined />}
                onClick={() => router.push("/home/past-greece-refugees")}
              >
                Get Details
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image
              className="w-full shadow-xl bg-white"
              src={require("../../assets/images/eq.png").default}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="mx-auto text-base max-w-prose  lg:max-w-none lg:pl-6 p-2">
              <div>
                <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
                  <Tag color="red">PAST EVENT</Tag>
                  <Tag color="blue">19/03/2023</Tag>
                </h2>
                <h3 className="mt-2 text-3xl leading-8 font-bold  text-f5f5f5 sm:text-4xl ">
                  Run.Walk.Ride.
                  <br /> For Earthquake Victims
                </h3>
              </div>
              <p className="text-blue-800 text-lg !mt-2">
                Special thanks to the generosity and support of our
                participants, volunteers, and partner organizations such as the
                Australian Relief Organisation (ARO), Amity College, and Galaxy
                Foundation.
              </p>
              <br />
              <Button
                type="primary"
                size="large"
                icon={<ArrowRightOutlined />}
                onClick={() => router.push("/home/past-earthquake-victims")}
              >
                Get Details
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PastEvents;
