import { Table } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { AfghanDonations } from "src/resources/AfghanDonations";
import Banner from "./Banner";

const PastAfghan = () => {
  const { lg } = useBreakpoint();
  const [dataSet, setDataSet] = useState([]);
  useEffect(() => {
    const sirali = [];
    AfghanDonations.map((d, index) => {
      sirali.push({ ...d, sira: index + 1 });
    });
    setDataSet(sirali);
  }, []);
  const columns = [
    {
      title: "Rank",
      dataIndex: "sira",
      className:
        lg && "w-16 bg-sky-900  text-sari text-3xl font-bold text-center",
    },
    {
      title: "Fundraiser Name",
      dataIndex: "fullname",
      className: lg && "text-2xl font-semibold text-sky-900",
    },
    {
      title: "Raised",
      dataIndex: "total",
      render: (total) => (
        <p>
          <span className="lg:text-base">Raised</span>
          <br />
          <span className="text-base lg:text-3xl text-kirmizi font-semibold">
            ${total}
          </span>
        </p>
      ),
    },
  ];
  return (
    <div className="bg-sari">
      <Banner />
      <div className="text-center justify-center items-center   w-full text-white  text-2xl lg:text-5xl ">
        <div className="player-wrapper">
          <ReactPlayer
            url="https://youtu.be/yTFsENiOTb0"
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
          />
        </div>

        <h1 className="text-blue-900 font-extrabold italic text-4xl pt-8 pb-2 text-center">
          LEADERBOARD
        </h1>

        <br />
        <div className="max-w-md mx-auto ">
          <Table
            showHeader={false}
            dataSource={dataSet}
            bordered
            columns={columns}
            className="max-w-full overflow-hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default PastAfghan;
