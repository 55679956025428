import { axios } from "./HttpClient";

export const AdminService = {
  newMember: (values) => {
    return axios.post("/members", values);
  },
  finalizeOrder: (values) => {
    return axios.post("/invoice/sendFinalOrder", values);
  },
  finalizeDonation: (values) => {
    return axios.post("/donations/sendFinalOrder", values);
  },
  getMember: (id) => {
    return axios.get("/members/" + id);
  },
  getMembersDonations: (isFull) => {
    return axios.get("/members/donations?isfull=" + isFull);
  },
  newContact: (values) => {
    return axios.post("/contacts", { ...values });
  },
  getContacts: () => {
    return axios.get("/contacts");
  },
  login: (values) => {
    return axios.post("/staff/login", values);
  },
  getDonations: () => {
    return axios.get("/donations");
  },
  getMembers: () => {
    return axios.get("/members");
  },
  changeIsOk: (id) => {
    return axios.put("/contacts/isok/" + id);
  },
  getExcel: () => {
    return axios.get("/members/excel", { responseType: "blob" });
  },
  getDonationsExcel: () => {
    return axios.get("/donations/excel", { responseType: "blob" });
  },
};
