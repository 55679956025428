import { Button, Col, Image, Row } from "antd";
import React from "react";
import { useHistory } from "react-router";
import MyDivider from "./MyDivider";

const Banner = () => {
  const router = useHistory();
  return (
    <div
      style={{
        backgroundColor: "#F29F05",
        zIndex: 9999999,
      }}
      className="-mt-40  lg:mt-0 pb-4"
    >
      <div className=" mx-auto lg:ml-2">
        <Row gutter={[16, 16]} align="middle">
          <Col
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="bg-sari w-full"
          >
            {/* <h1 className="text-blue-900 font-extrabold italic text-4xl pt-8 pb-2 text-center">
              ORGANISED BY
            </h1> 
            <MyDivider />*/}
            <div className="p-4 text-center border-2 shadow-lg border-white m-2 lg:m-4 bg-white bg-opacity-50 ">
              <img
                src={require("../../assets/images/afd.png").default}
                alt="afd"
                className="mx-auto h-20 mb-4 mt-2"
              />
              <h1 className="text-blue-900 font-bold  text-3xl uppercase mb-1">
                Thanks everyone
              </h1>
              <MyDivider />
              <h1 className="text-black text-left  text-xl mt-4">
                The CEO of <b>Refugee Council of Australia</b>, Mr Paul Power,
                and Deputy CEO, Adama Kamara, visited{" "}
                <b>Advocates for Dignity</b> Centre in Auburn in 16th of
                February 2022.
                <br />
                <br />
                AFD President, <b>Mr Mehmet Saral</b>, presented Mr Power, a{" "}
                <b>cheque for $10,000</b> as a result of the fundraising
                activities AFD organised called{" "}
                <b>"Run, Walk & Ride for Afghan Refugees"</b> on Sunday, 6th of
                February 2022 in Sydney Olympic Park Wentworth Common. <br />
                <br />
                A total of 78 participants took part in one of 5 activities
                planned that day, which included a 50km and 20km cycle, 10km and
                5km Run and 5km Walk.
                <br />
                <br />
                Mr Power said,{" "}
                <i>
                  "As the Refugee Council, our plan is to lobby the Australian
                  government to increase the Afghan refugee intake to 20,000
                  refugees in 2022".
                </i>
                <br />
                He continued,{" "}
                <i>
                  "We really thank AFD to help us raise these funds in order for
                  us to continue this important work"
                </i>
                <br />
                <br />
                Mr Power said that he is on the Advisory Board to the
                Immigration, Citizenship, Migrant Services and Multicultural
                Aff​airs, The Hon Alex Hawke, for refugee intake to Australia.
                <br />
                <br />
                Mr Power ran 10km during the fundraiser program organised by AFD
                with his youngest daughter.
                <br />
                <br />
                Ms Kamara also did a 5km walk during the AFD event to raise
                funds.
                <br />
              </h1>
            </div>
          </Col>
          <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="p-2 lg:pr-4 space-y-4 text-center">
              <Image
                src={require("../../assets/images/donations.jpg").default}
                alt="banner"
                className="w-full max-w-3xl border-4 border-white mr-4 shadow-xl rounded"
              />
              <Image
                src={require("../../assets/images/cheque.jpg").default}
                alt="banner"
                className="w-full max-w-3xl border-4 border-white mr-4 shadow-xl rounded"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Banner;
