import React, { createContext, useState } from "react";

const initialState = {};
export const GlobalContext = createContext(initialState);
export const GlobalContextProvider = ({ children }) => {
  const [role, setRole] = useState(null);
  return (
    <GlobalContext.Provider value={{ role, setRole }}>
      {children}
    </GlobalContext.Provider>
  );
};
