import React, { useEffect, useState } from "react";
import { Button, Col, Layout, Menu, Row, Tag } from "antd";
import {
  GiftOutlined,
  HeartOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  Link,
  useHistory,
} from "react-router-dom";

import Contacts from "src/components/admin/Contacts";
import DonationsList from "src/components/admin/DonationsList";
import Members from "src/components/admin/Members";

const AdminLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg, sm } = useBreakpoint();
  const [isSider, setIsSider] = useState(false);
  const location = useLocation();
  const router = useHistory();
  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      {!lg && (
        <div className="login-page">
          <div className=" flex items-center justify-center bg-gray-50 bg-opacity-80 rounded-lg py-6 px-4 sm:px-6 lg:px-8 shadow-lg border border-gray-400">
            <div className="max-w-md w-full space-y-8 text-center">
              <div>
                <img
                  className="mx-auto h-14 w-auto"
                  src={require("../assets/images/logo.png").default}
                  alt="Logo"
                />
                <br />
                <p>
                  Your screen size is very small. <br />
                  Large screen required for this application form.
                  <br />
                  Please connect desktop computer.
                  <br /> Thank you
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      )}
      {lg && (
        <>
          <Header
            style={{
              height: "75px",
              lineHeight: "75px",
              position: lg ? "fixed" : "unset",
              zIndex: 11111,
              width: "100%",
              backgroundColor: "white",
              borderBottom: "1px solid #ccc",
              boxShadow: "1px 1px 10px #ddd",
            }}
          >
            <Row
              justify={!sm ? "start" : "space-between"}
              align="middle"
              className="px-4"
            >
              <Col>
                <Button
                  icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  size="large"
                  onClick={() => setIsSider(!isSider)}
                />
              </Col>
              <Col>
                <img
                  onClick={() => router.push("/home/homepage")}
                  src={require("../assets/images/logo.png").default}
                  alt="logo"
                  style={{
                    maxWidth: "220px",
                    marginLeft: !sm ? "20px" : "",
                    cursor: "pointer",
                  }}
                />
              </Col>
              {lg && (
                <Col>
                  <Button
                    onClick={() => {
                      localStorage.clear();
                      router.push("/");
                    }}
                    type="primary"
                    icon={<LogoutOutlined />}
                  >
                    Log Out
                  </Button>
                </Col>
              )}
            </Row>
          </Header>
          <Layout>
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              trigger={null}
              collapsible
              collapsed={isSider}
              theme="light"
              className={!lg ? "mobil-sider" : "desktop-sider"}
            >
              <br />
              <Tag
                color="blue"
                style={{
                  width: "calc(100% - 40px)",
                  textAlign: "center",
                  margin: "5px 20px 0 20px",
                }}
              >
                Admin Menu
              </Tag>
              <Menu
                className="amenu"
                onSelect={() => {
                  !lg && setIsSider(true);
                }}
                theme="light"
                mode="inline"
                defaultSelectedKeys={[location.pathname.split("/").pop()]}
              >
                <Menu.Item key="members" icon={<UserOutlined />}>
                  <Link to="/admin/members">Participants</Link>
                </Menu.Item>
                <Menu.Item key="donations" icon={<GiftOutlined />}>
                  <Link to="/admin/donations">Donations</Link>
                </Menu.Item>
                <Menu.Item key="contacts" icon={<MailOutlined />}>
                  <Link to="/admin/contacts">Contacts</Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Content
              style={{
                marginTop: lg ? "75px" : undefined,
                padding: "10px",
                borderLeft: "1px solid #ddd",
                minHeight: "calc(100vh - 150px)",
              }}
            >
              <Switch>
                <Route path="/admin" exact>
                  <Redirect to="/admin/members" />
                </Route>

                <Route path="/admin/contacts" component={Contacts} />
                <Route path="/admin/donations" component={DonationsList} />
                <Route path="/admin/members" component={Members} />

                <Route>
                  <Redirect to="/404" />
                </Route>
              </Switch>
            </Content>
          </Layout>
          <Footer className="footer">
            <div>
              <p className="text-small text-gray-400 text-center">
                <span className="text-emerald-600 font-semibold">
                  &copy; runwalkride.com.au
                </span>
                {", "}2021 . All rights reserved. <br />
                Developed by Softmile with <HeartOutlined /> React
              </p>
            </div>
          </Footer>{" "}
        </>
      )}
    </Layout>
  );
};

export default AdminLayout;
