import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const DonationsList = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [edonations, setEdonations] = useState(null);
  const [mdonations, setMdonations] = useState(null);
  const [mtotal, setMtotal] = useState(0);
  const [etotal, setEtotal] = useState(0);
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const searchInput = useRef();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getDonations().then((data) => {
        console.log(data.data);
        setEdonations(data.data.edonations);
        setMdonations(data.data.mdonations);
        setMtotal(data.data.mtotal);
        setEtotal(data.data.etotal);
      });
    }
  }, [initialRender]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });
  const mcolumns = [
    {
      title: "Donor",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => GlobalVariables.compare(a.name, b.name),
    },
    {
      title: "Member",
      dataIndex: "member",
      ...getColumnSearchProps("member"),
      sorter: (a, b) => GlobalVariables.compare(a.member, b.member),
    },
    {
      title: "Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YY HH:mm"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => GlobalVariables.compare(a.email, b.email),
    },
    {
      title: "Organisation",
      dataIndex: "organisation",
      ...getColumnSearchProps("organisation"),
      sorter: (a, b) => GlobalVariables.compare(a.organisation, b.organisation),
    },
    {
      title: "Message",
      dataIndex: "message",
      ...getColumnSearchProps("message"),
      sorter: (a, b) => GlobalVariables.compare(a.message, b.message),
    },
    {
      title: "Anonymous?",
      dataIndex: "isAnonymously",
      render: (isAnonymously) => (isAnonymously ? "YES" : "No"),
    },

    {
      title: "Amount",
      dataIndex: "total",
      render: (total) => `$${total}`,
      className: `font-bold text-lg text-center`,
      sorter: (a, b) => a.total - b.total,
    },
  ];
  const ecolumns = [
    {
      title: "Donor",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => GlobalVariables.compare(a.name, b.name),
    },
    {
      title: "Member",
      dataIndex: "member",
      render: () => "Event Donation",
    },
    {
      title: "Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YY HH:mm"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => GlobalVariables.compare(a.email, b.email),
    },
    {
      title: "Organisation",
      dataIndex: "organisation",
      ...getColumnSearchProps("organisation"),
      sorter: (a, b) => GlobalVariables.compare(a.organisation, b.organisation),
    },
    {
      title: "Message",
      dataIndex: "message",
      ...getColumnSearchProps("message"),
      sorter: (a, b) => GlobalVariables.compare(a.message, b.message),
    },
    {
      title: "Anonymous?",
      dataIndex: "isAnonymously",
      render: (isAnonymously) => (isAnonymously ? "YES" : "No"),
    },

    {
      title: "Amount",
      dataIndex: "total",
      render: (total) => `$${total}`,
      className: "font-bold text-lg text-right",
      sorter: (a, b) => a.total - b.total,
    },
  ];
  const exportExcel = () => {
    AdminService.getDonationsExcel().then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "donations.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div className="max-w-7xl">
      <Button
        className="m-2"
        style={{ float: "left" }}
        type="primary"
        icon={<FileExcelOutlined />}
        onClick={exportExcel}
      >
        Export Excel
      </Button>
      <div className="text-sari bg-blue-900 text-center p-4 uppercase text-2xl lg:text-4xl font-bold italic">
        Participants Donations - ${mtotal}
      </div>
      <Table
        loading={mdonations === null}
        columns={mcolumns}
        bordered
        dataSource={mdonations}
        rowKey="id"
        size="small"
        className="border border-gray-300 shadow-xl"
        pagination={{ pageSize: 20 }}
        title={() =>
          mdonations && (
            <span className="text-lg text-sky-500">
              Total donations count : {mdonations.length}
            </span>
          )
        }
      />
      <br />
      <br />
      <div className="text-sari bg-blue-900 text-center p-4 uppercase text-2xl lg:text-4xl font-bold italic">
        Event Donations - ${etotal}
      </div>
      <Table
        loading={edonations === null}
        columns={ecolumns}
        bordered
        dataSource={edonations}
        rowKey="id"
        size="small"
        className="border border-gray-300 shadow-xl"
        pagination={{ pageSize: 20 }}
        title={() =>
          edonations && (
            <span className="text-lg text-sky-500">
              Total donations count : {edonations.length}
            </span>
          )
        }
      />
      <br />
      <br />
    </div>
  );
};

export default DonationsList;
