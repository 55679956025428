import { HeartOutlined } from "@ant-design/icons";
import { Button, Table, Input, Row, Col } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";

const Fundraisers = ({ title, isFull }) => {
  const [initialRender, setInitialRender] = useState(true);
  const [fundraisers, setFundraisers] = useState(null);
  const { lg } = useBreakpoint();
  const [depo, setDepo] = useState([]);
  const router = useHistory();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getMembersDonations(isFull).then((data) => {
        console.log("fundraisers", data.data);
        const sirali = [];
        data.data.map((d, index) => {
          sirali.push({ ...d, sira: index + 1 });
        });
        setDepo(sirali);
        setFundraisers(sirali);
      });
    }
  }, [initialRender, isFull]);

  const columns = [
    {
      title: "Rank",
      dataIndex: "sira",
      className:
        lg && "w-16 bg-sky-900  text-sari text-3xl font-bold text-center",
    },
    {
      title: "Fundraiser Name",
      className: lg && "text-2xl font-semibold text-sky-900",
      render: (item) => (
        <>
          <span>{item.fullname}</span>
          <span className="!ml-2 text-base text-yellow-600">
            {" "}
            ({item.state.slice(0, 3)}){" "}
          </span>
        </>
      ),
    },
    {
      title: "Raised",
      dataIndex: "total",
      render: (total) => (
        <p>
          <span className="lg:text-base">Raised</span>
          <br />
          <span className="text-base lg:text-3xl text-kirmizi font-semibold">
            ${total}
          </span>
        </p>
      ),
    },
    {
      title: "Action",
      render: (item) => (
        <Button
          size={lg ? "large" : "small"}
          type="primary"
          icon={lg && <HeartOutlined />}
          onClick={() => router.push("/home/fundraisers/" + item.slug)}
        >
          Donate
        </Button>
      ),
      className: "w-24",
    },
  ];
  const onSearch = (v) => {
    console.log(v);
    setFundraisers(
      depo.filter(
        (data) => data.fullname.toLowerCase().indexOf(v.toLowerCase()) !== -1
      )
    );
  };
  return (
    <div className="bg-sari p-2 lg:p-6 ">
      <div className="w-full text-center">
        <br />
        <img
          src={require("../../assets/images/banner.png").default}
          alt="banner"
          className="mx-auto w-64 lg:w-1/2 max-w-full"
        />
      </div>
      <div className="max-w-4xl mx-auto mt-6">
        {isFull && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <h1 className="text-blue-900 font-extrabold italic text-2xl pt-8 pb-2 text-center">
                SEARCH FOR A FUNDRAISER
              </h1>

              <Input.Search
                placeholder="Type fundraiser name for donation"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={onSearch}
                className="max-w-md"
              />
              <br />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="text-center"
            >
              <h1 className="text-blue-900 font-extrabold italic text-2xl pt-8 pb-2 text-center">
                OR MAKE A DONATION TO THE EVENT
              </h1>
              <Button
                size="large"
                type="primary"
                block
                className="mx-auto font-semibold"
                onClick={() => router.push("/home/donation")}
              >
                DONATE TO EVENT
              </Button>
            </Col>
          </Row>
        )}
        {!isFull && (
          <h1 className="text-blue-900 font-extrabold italic text-4xl pt-8 pb-2 text-center">
            LEADERBOARD
          </h1>
        )}
        <br />
        <Table
          showHeader={false}
          pagination={isFull ? { pageSize: 10 } : false}
          dataSource={fundraisers}
          bordered
          loading={fundraisers === null}
          columns={columns}
          className="max-w-full overflow-hidden"
        />
        {!isFull && (
          <Button
            type="primary"
            size="large"
            block
            className="my-6 py-4 h-16 text-xl"
            onClick={() => router.push("/home/fundraisers")}
          >
            Get Fundraisers List
          </Button>
        )}
      </div>
    </div>
  );
};

export default Fundraisers;
