import React from "react";
const AboutPage = () => {
  return (
    <div className="bg-sari overflow-hidden lg:mt-0 -mt-40">
      <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-sari absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
              ABOUT
            </h2>
            <h3 className="mt-2 text-3xl leading-8 font-bold tracking-tight text-f5f5f5 sm:text-4xl ">
              Run.Walk.Ride.
              <br /> For Turkish & Syrian Earthquake victims
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className=" object-cover object-center border border-gray-300 shadow-xl"
                    src={require("../../assets/images/deprem.jpeg").default}
                    alt="Logo"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="booker  prose prose-indigo  mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 space-y-4 text-sky-900 text-lg">
              <p>
                An earthquake of magnitude 7.7 affected 10 provinces in
                South-East Turkey. It was centred in the province of
                Kahramanmaraş, and shook the provinces of Hatay, Adana,
                Gaziantep, Malatya, Kilis, Adıyaman, Diyarbakır, Şanlıurfa and
                Osmaniye, as well as some parts of Turkey’s neighbour Syria.
                Many Turkish and Syrian people died and there are also many
                waiting to be rescued under the rubble.
              </p>
              <p>
                Our condolences go out to the people of Turkey and Syria,
                especially to the relatives of those who lost their lives.
              </p>
              <p>
                Disasters and calamities remind us to take every precaution
                within the framework of causes, to help in the spirit of unity,
                to extend a hand to the needy, and to pray to the Almighty to
                open his hand and protect these two affected countries and all
                humanity from all kinds of disasters.
              </p>
              <p>
                {" "}
                So far, a summary of this earthquake disaster are as follows:
              </p>
              <div className="ml-2 lg:ml-5">
                • More than 36,000 people have been killed.
                <br />
                • Tens of thousands of people have been injured.
                <br />
                • A number of property developers have been blamed for building
                collapses in Turkey.
                <br />
                • A woman was rescued after 175 hours (more than 7 days) under
                the rubble, among the latest in a series of harrowing stories of
                success as workers in Turkey continue search efforts.
                <br />
                • Rescue operations are over in rebel-held areas of northwest
                Syria, the White Helmets volunteer organization said. Relief
                efforts there have been complicated by a long-running civil war.
                <br />
              </div>
              <p>
                We ask you to partake in this physical activity to help rescue
                efforts in the affected regions to protect its citizens, who are
                now left homeless, destitute, in need of all kinds of help, with
                a spirit of mobilization. We wish patience, strength and
                fortitude to the search and rescue teams that responded to the
                disaster.
              </p>
              <p>
                We wish mercy and forgiveness from the Almighty to those who
                lost their lives in this tragic event, patience and fortitude to
                those who lost their loved ones, a speedy recovery to the
                injured citizens in the affected regions, and a safe rescue to
                the citizens waiting to be rescued under the rubble.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
