export const TurkiyeDonations = [
  {
    key: 155,
    id: 155,
    fullname: "Mehmet Saral",
    slug: "mehmet-saral-155",
    state: "Sydney",
    total: 3000,
  },
  {
    key: 162,
    id: 162,
    fullname: "Said Yalcin",
    slug: "said-yalcin-162",
    state: "Sydney",
    total: 1385,
  },
  {
    key: 181,
    id: 181,
    fullname: "Mungun-Erdene  Tsogtoo",
    slug: "mungun-erdene-tsogtoo-181",
    state: "Sydney",
    total: 1205,
  },
  {
    key: 183,
    id: 183,
    fullname: "Hannia Rawas",
    slug: "hannia-rawas-183",
    state: "Melbourne",
    total: 1200,
  },
  {
    key: 190,
    id: 190,
    fullname: "Hatice  Kizmaz",
    slug: "hatice-kizmaz-190",
    state: "Sydney",
    total: 980,
  },
  {
    key: 200,
    id: 200,
    fullname: "Ahmet Keskin",
    slug: "ahmet-keskin-200",
    state: "Melbourne",
    total: 820,
  },
  {
    key: 185,
    id: 185,
    fullname: "Turker Imer",
    slug: "turker-imer-185",
    state: "Melbourne",
    total: 800,
  },
  {
    key: 160,
    id: 160,
    fullname: "Serkan Iner",
    slug: "serkan-iner-160",
    state: "Sydney",
    total: 750,
  },
  {
    key: 213,
    id: 213,
    fullname: "Matthew Phillips",
    slug: "matthew-phillips-213",
    state: "Sydney",
    total: 610,
  },
  {
    key: 209,
    id: 209,
    fullname: "M Erdal  Aslan ",
    slug: "m-erdal-aslan-209",
    state: "Adelaide",
    total: 570,
  },
  {
    key: 159,
    id: 159,
    fullname: "Kerami Goz",
    slug: "kerami-goz-159",
    state: "Sydney",
    total: 570,
  },
  {
    key: 188,
    id: 188,
    fullname: "sinan Baydogan",
    slug: "sinan-baydogan-188",
    state: "Melbourne",
    total: 540,
  },
  {
    key: 229,
    id: 229,
    fullname: "Emre A",
    slug: "emre-a-229",
    state: "Melbourne",
    total: 520,
  },
  {
    key: 203,
    id: 203,
    fullname: "Sukan Alkin",
    slug: "sukan-alkin-203",
    state: "Adelaide",
    total: 380,
  },
  {
    key: 178,
    id: 178,
    fullname: "ABDUL CELIL GELIM",
    slug: "abdul-celil-gelim-178",
    state: "Melbourne",
    total: 350,
  },
  {
    key: 201,
    id: 201,
    fullname: "Paul Power",
    slug: "paul-power-201",
    state: "Sydney",
    total: 320,
  },
  {
    key: 167,
    id: 167,
    fullname: "Recep Aydogan",
    slug: "recep-aydogan-167",
    state: "Sydney",
    total: 300,
  },
  {
    key: 169,
    id: 169,
    fullname: "Muhammad Asim",
    slug: "muhammad-asim-169",
    state: "Sydney",
    total: 245,
  },
  {
    key: 245,
    id: 245,
    fullname: "Nisma  Taleb",
    slug: "nisma-taleb-245",
    state: "Melbourne",
    total: 205,
  },
  {
    key: 198,
    id: 198,
    fullname: "Farid  Faiz ",
    slug: "farid-faiz-198",
    state: "Sydney",
    total: 200,
  },
  {
    key: 153,
    id: 153,
    fullname: "Sedat Yazici",
    slug: "sedat-yazici-153",
    state: "Sydney",
    total: 200,
  },
  {
    key: 172,
    id: 172,
    fullname: "Abdul Habib",
    slug: "abdul-habib-172",
    state: "Sydney",
    total: 200,
  },
  {
    key: 222,
    id: 222,
    fullname: "Shania Ritchie",
    slug: "shania-ritchie-222",
    state: "Adelaide",
    total: 155,
  },
  {
    key: 180,
    id: 180,
    fullname: "Gulumser Goz",
    slug: "gulumser-goz-180",
    state: "Sydney",
    total: 130,
  },
  {
    key: 191,
    id: 191,
    fullname: "Jafar Noor",
    slug: "jafar-noor-191",
    state: "Sydney",
    total: 125,
  },
  {
    key: 210,
    id: 210,
    fullname: "Omer Hodzic",
    slug: "omer-hodzic-210",
    state: "Sydney",
    total: 125,
  },
  {
    key: 157,
    id: 157,
    fullname: "Figen  Yavuz",
    slug: "figen-yavuz-157",
    state: "Sydney",
    total: 120,
  },
  {
    key: 223,
    id: 223,
    fullname: "Huseyin  TOKER",
    slug: "huseyin-toker-223",
    state: "Adelaide",
    total: 120,
  },
  {
    key: 189,
    id: 189,
    fullname: "Abdullah Shakeel",
    slug: "abdullah-shakeel-189",
    state: "Melbourne",
    total: 110,
  },
  {
    key: 199,
    id: 199,
    fullname: "Ahmed Egiz",
    slug: "ahmed-egiz-199",
    state: "Sydney",
    total: 100,
  },
  {
    key: 165,
    id: 165,
    fullname: "Yasser  Waheed",
    slug: "yasser-waheed-165",
    state: "Sydney",
    total: 100,
  },
  {
    key: 196,
    id: 196,
    fullname: "Khin  Maung ",
    slug: "khin-maung-196",
    state: "Sydney",
    total: 100,
  },
  {
    key: 204,
    id: 204,
    fullname: "Salih Gulhan",
    slug: "salih-gulhan-204",
    state: "Adelaide",
    total: 100,
  },
  {
    key: 212,
    id: 212,
    fullname: "Seydanur Selvi",
    slug: "seydanur-selvi-212",
    state: "Adelaide",
    total: 92,
  },
  {
    key: 161,
    id: 161,
    fullname: "Yucel Ugurlu",
    slug: "yucel-ugurlu-161",
    state: "Sydney",
    total: 80,
  },
  {
    key: 166,
    id: 166,
    fullname: "Othman Alrawi",
    slug: "othman-alrawi-166",
    state: "Sydney",
    total: 75,
  },
  {
    key: 171,
    id: 171,
    fullname: "Fatih Sen",
    slug: "fatih-sen-171",
    state: "Sydney",
    total: 70,
  },
  {
    key: 163,
    id: 163,
    fullname: "Ahmet Arif  Haktan",
    slug: "ahmet-arif-haktan-163",
    state: "Sydney",
    total: 70,
  },
  {
    key: 218,
    id: 218,
    fullname: "Aref Zreika",
    slug: "aref-zreika-218",
    state: "Sydney",
    total: 70,
  },
  {
    key: 220,
    id: 220,
    fullname: "ZAFER POLAT",
    slug: "zafer-polat-220",
    state: "Sydney",
    total: 70,
  },
  {
    key: 207,
    id: 207,
    fullname: "Shamara  Haque",
    slug: "shamara-haque-207",
    state: "Sydney",
    total: 60,
  },
  {
    key: 231,
    id: 231,
    fullname: "Salar Aga",
    slug: "salar-aga-231",
    state: "Sydney",
    total: 60,
  },
  {
    key: 202,
    id: 202,
    fullname: "Mehmet Ozalp",
    slug: "mehmet-ozalp-202",
    state: "Sydney",
    total: 50,
  },
  {
    key: 168,
    id: 168,
    fullname: "Moe  Thwe ",
    slug: "moe-thwe-168",
    state: "Sydney",
    total: 50,
  },
  {
    key: 205,
    id: 205,
    fullname: "Gurkan Gurbuz",
    slug: "gurkan-gurbuz-205",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 206,
    id: 206,
    fullname: "Ali Goru",
    slug: "ali-goru-206",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 184,
    id: 184,
    fullname: "BAHADIR YAVUZ",
    slug: "bahadir-yavuz-184",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 214,
    id: 214,
    fullname: "Eldaniz  Mirzayev ",
    slug: "eldaniz-mirzayev-214",
    state: "Adelaide",
    total: 50,
  },
  {
    key: 239,
    id: 239,
    fullname: "Mark  Scorzelli ",
    slug: "mark-scorzelli-239",
    state: "Sydney",
    total: 50,
  },
  {
    key: 238,
    id: 238,
    fullname: "Rinat Farukshin",
    slug: "rinat-farukshin-238",
    state: "Sydney",
    total: 50,
  },
  {
    key: 192,
    id: 192,
    fullname: "Firaz Osman",
    slug: "firaz-osman-192",
    state: "Sydney",
    total: 50,
  },
  {
    key: 194,
    id: 194,
    fullname: "Ali Javam",
    slug: "ali-javam-194",
    state: "Sydney",
    total: 50,
  },
  {
    key: 156,
    id: 156,
    fullname: "Semih YILMAZ",
    slug: "semih-yilmaz-156",
    state: "Sydney",
    total: 50,
  },
  {
    key: 228,
    id: 228,
    fullname: "Nesrin Kurucu",
    slug: "nesrin-kurucu-228",
    state: "Melbourne",
    total: 50,
  },
  {
    key: 187,
    id: 187,
    fullname: "Tarek Sari",
    slug: "tarek-sari-187",
    state: "Sydney",
    total: 50,
  },
  {
    key: 173,
    id: 173,
    fullname: "Sayeda Naher",
    slug: "sayeda-naher-173",
    state: "Sydney",
    total: 50,
  },
  {
    key: 177,
    id: 177,
    fullname: "Keen Brown",
    slug: "keen-brown-177",
    state: "Sydney",
    total: 40,
  },
  {
    key: 176,
    id: 176,
    fullname: "Duygu Bostanci",
    slug: "duygu-bostanci-176",
    state: "Sydney",
    total: 40,
  },
  {
    key: 175,
    id: 175,
    fullname: "Hatice  Poyraz",
    slug: "hatice-poyraz-175",
    state: "Sydney",
    total: 40,
  },
  {
    key: 164,
    id: 164,
    fullname: "Ramazan  Yavuz",
    slug: "ramazan-yavuz-164",
    state: "Sydney",
    total: 40,
  },
  {
    key: 211,
    id: 211,
    fullname: "Mustafa Abay",
    slug: "mustafa-abay-211",
    state: "Sydney",
    total: 40,
  },
  {
    key: 179,
    id: 179,
    fullname: "Nataliia Pyhor",
    slug: "nataliia-pyhor-179",
    state: "Sydney",
    total: 40,
  },
  {
    key: 240,
    id: 240,
    fullname: "Thidarat Yaemutai",
    slug: "thidarat-yaemutai-240",
    state: "Sydney",
    total: 30,
  },
  {
    key: 230,
    id: 230,
    fullname: "Hilal Yalcin",
    slug: "hilal-yalcin-230",
    state: "Sydney",
    total: 30,
  },
  {
    key: 227,
    id: 227,
    fullname: "Guner Hussein",
    slug: "guner-hussein-227",
    state: "Melbourne",
    total: 25,
  },
  {
    key: 170,
    id: 170,
    fullname: "Mujgan Berber",
    slug: "mujgan-berber-170",
    state: "Sydney",
    total: 20,
  },
  {
    key: 221,
    id: 221,
    fullname: "NAZAN POLAT",
    slug: "nazan-polat-221",
    state: "Sydney",
    total: 20,
  },
  {
    key: 158,
    id: 158,
    fullname: "Ismail Ibrahim ",
    slug: "ismail-ibrahim-158",
    state: "Sydney",
    total: 20,
  },
  {
    key: 224,
    id: 224,
    fullname: "AHMET OZKAYA",
    slug: "ahmet-ozkaya-224",
    state: "Melbourne",
    total: 20,
  },
  {
    key: 208,
    id: 208,
    fullname: "Zehra  Yalcin",
    slug: "zehra-yalcin-208",
    state: "Sydney",
    total: 20,
  },
  {
    key: 216,
    id: 216,
    fullname: "Derya Bartli Phillips",
    slug: "derya-bartli-phillips-216",
    state: "Sydney",
    total: 20,
  },

  {
    key: 232,
    id: 232,
    fullname: "Ahmad el bikai",
    slug: "ahmad-el-bikai-232",
    state: "Sydney",
    total: 20,
  },
  {
    key: 186,
    id: 186,
    fullname: "Mete Samci",
    slug: "mete-samci-186",
    state: "Melbourne",
    total: 20,
  },
  {
    key: 215,
    id: 215,
    fullname: "Yasemin Phillips",
    slug: "yasemin-phillips-215",
    state: "Sydney",
    total: 20,
  },
  {
    key: 244,
    id: 244,
    fullname: "Taha Mollah",
    slug: "taha-mollah-244",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 195,
    id: 195,
    fullname: "Thomas Wright",
    slug: "thomas-wright-195",
    state: "Sydney",
    total: 0,
  },
  {
    key: 219,
    id: 219,
    fullname: "Alaa Elhusain",
    slug: "alaa-elhusain-219",
    state: "Sydney",
    total: 0,
  },

  {
    key: 243,
    id: 243,
    fullname: "Basem Hagemrad",
    slug: "basem-hagemrad-243",
    state: "Sydney",
    total: 0,
  },
  {
    key: 233,
    id: 233,
    fullname: "Mehmet Ciftci",
    slug: "mehmet-ciftci-233",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 234,
    id: 234,
    fullname: "Taha Mollah",
    slug: "taha-mollah-234",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 235,
    id: 235,
    fullname: "Mahmoud Kashoa",
    slug: "mahmoud-kashoa-235",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 236,
    id: 236,
    fullname: "Ammar Sultan",
    slug: "ammar-sultan-236",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 237,
    id: 237,
    fullname: "Bilal Aydemir",
    slug: "bilal-aydemir-237",
    state: "Sydney",
    total: 0,
  },
  {
    key: 174,
    id: 174,
    fullname: "Tarek Adasi",
    slug: "tarek-adasi-174",
    state: "Sydney",
    total: 0,
  },
  {
    key: 182,
    id: 182,
    fullname: "AHMET RUFAI ASLAN",
    slug: "ahmet-rufai-aslan-182",
    state: "Sydney",
    total: 0,
  },
  {
    key: 217,
    id: 217,
    fullname: "Ahmet Polat",
    slug: "ahmet-polat-217",
    state: "Sydney",
    total: 0,
  },
  {
    key: 241,
    id: 241,
    fullname: "Fatih Karabiyik",
    slug: "fatih-karabiyik-241",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 242,
    id: 242,
    fullname: "Zakariya Sari",
    slug: "zakariya-sari-242",
    state: "Sydney",
    total: 0,
  },
  {
    key: 225,
    id: 225,
    fullname: "Aaqib Suhale",
    slug: "aaqib-suhale-225",
    state: "Melbourne",
    total: 0,
  },
  {
    key: 226,
    id: 226,
    fullname: "Sanjana Samrin",
    slug: "sanjana-samrin-226",
    state: "Sydney",
    total: 0,
  },
  {
    key: 197,
    id: 197,
    fullname: "Cengiz Altinors",
    slug: "cengiz-altinors-197",
    state: "Melbourne",
    total: 0,
  },
];
